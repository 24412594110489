<template>
  <div>
    <b-row class="justify-content-center">
      <b-col>
        <b-carousel
        fluid
        id="carousel-fade"
        style="text-shadow: 0px 0px 2px #000;"
        :interval="7000"
        fade
        controls
      >
        <b-carousel-slide
        class="b-carousel-slide"
        caption="Product Design"
        text="We take your idea and make it a reality, from conception to prototype to production."
        video
        :img-src="require('../assets/carousel4.jpg')"
        ></b-carousel-slide>
        <b-carousel-slide
        caption="Contract Manufacturing"
        class="b-carousel-slide"
        text="We have full in-house manufacturing capabilities for small to medium scale PCB production."
        :img-src="require('../assets/carousel2.jpg')"
        ></b-carousel-slide>
        <b-carousel-slide
        class="b-carousel-slide"
        caption="PCB Design"
        text="We have experience designing for the automotive, industrial and medical industries."
        :img-src="require('../assets/carousel1.jpg')"
        ></b-carousel-slide>
        <b-carousel-slide
        caption="Software Development"
        class="b-carousel-slide"
        text="We have experience with the entire software stack, from embedded firmware to desktop applications and cloud technologies."
        :img-src="require('../assets/carousel3.jpg')"
        ></b-carousel-slide>
      </b-carousel>
      </b-col>
    </b-row>

    <br/>

    <b-container>  
    
    <Blurb>
      All-Systems Electronics have been <b>designing and manufacturing</b> locally in Sydney, Australia, for over <b>40 years</b>.
    </Blurb>

    <br/>
    
    <b-row>
      <b-col cols="6" md="3" align="center">
        <IndustrySummary
          title="Industrial"
          :iconPath="require('../assets/IndustryIconIndustrial.png')"
          :dotPoints="[
            'Control & Automation',
            'Systems Integration',
            'Agriculture & Mining',
          ]"
        ></IndustrySummary>
      </b-col>
      <b-col cols="6" md="3" align="center">
        <IndustrySummary
          title="Medical"
          :iconPath="require('../assets/IndustryIconMedical.png')"
          :dotPoints="[
            'Alarm Systems',
            'Australian Standards',
            'Mission Critical',
          ]"
        ></IndustrySummary>
      </b-col>
      <b-col cols="6" md="3" align="center">
        <IndustrySummary
          title="Automotive"
          :iconPath="require('../assets/IndustryIconAuto.png')"
          :dotPoints="[
            'High-Performance',
            'Industrial Automotive',
            'Aftermarket',
          ]"
        ></IndustrySummary>
      </b-col>
      <b-col cols="6" md="3" align="center">
        <IndustrySummary
          title="IOT"
          :iconPath="require('../assets/IndustryIconIOT.png')"
          :dotPoints="[
            'Cloud Technologies',
            'Sensor Networks',
            'Low Power',
          ]"
        ></IndustrySummary>
      </b-col>
    </b-row>

    <b-row>
      <b-col align="center">
      <b-form-text text-variant="secondary">
        <h6 class="d-lg-none">Concept &xrarr; Design &xrarr; Prototype &xrarr; Production</h6>
        <h5 class="d-none d-lg-block">Concept &xrarr; Design &xrarr; Prototype &xrarr; Production</h5>
      </b-form-text>
      </b-col>
    </b-row>
    
    <!--  Add a small div with a slightly dynamic height to try to frame the top of the page on normal displays.
          We should JUST see the horizontal line at the bottom on a FHD display, in Chrome, with a bookmark bar turned on.
          The benchmark is Apple's home page, where you JUST see the top of the next item.
          The max-height option makes sure it doesn't stretch any larger on high-res displays and mobile phones which are portrait.
    -->
    <div style="height:8vh; max-height:5rem;"></div>

    <hr/>

    <MainSection
      title="Complete Product Design"
      textSide="left"
      :imagePath="require('../assets/CaseFrontRunning.png')"
      >
        With multiple custom PCB's, an ARM Microcontroller, and a range of software from embedded firmware to a desktop configuration utility and remote database connectivity, the AlcoMeasure showcases All-System's expertise at creating an entire product ecosystem.<br/>
        <br/>
        From engaging a local Industrial Designer, to local sheet metal fabrication, custom aluminium and plastic machining, All-Systems is capable of working with a wide variety of local industries to help design your next product.<br/>
        <br/>
        We are the proud designer and manufacturer of the AlcoMeasure wall mounting breathalyser. <!-- Visit <b-link href="https://www.alcomeasure.com.au" target="_blank">alcomeasure.com.au</b-link> for more information.-->
        The AlcoMeasure WM1 is certified to AS 3547 by SAI Global.
        Visit <b-link href="https://breathalyser.com.au/products/category/wall-mounted-breathalysers" target="_blank">Breathalyser Sales & Service</b-link> for more information.
    </MainSection>

    <hr/>

    <MainSection
      title="Latest Technology"
      textSide="right"
      :imagePath="require('../assets/HZD4060_FrontDrawing.png')"
      >
        All-Systems first started designing with the Motorola 6809 microprocessor, vacuum fluorescent displays and pre-IBM compatible PC's.<br/>
        <br/>
        We now use the latest embedded technologies, including ARM processors, LCD graphic displays, touchscreen panel PC's, and the latest proprietary and Linux based Operating Systems.<br/>
        <br/>
        Some technologies that can be designed into your product are:
        <ul>
          <li>Wireless technologies, such as Wi-Fi, Bluetooth, and Zigbee.</li>
          <li>Storage options, such as on-board flash or EEPROM, or an SD Card.</li>
          <li>Mobile apps, IOT cloud connectivity, database interactivity.</li>
          <li>LED or LCD graphic displays, with resistive or capacitive touch.</li>
        </ul>
        Working with BOC Limited, we are the proud designer and manufacturer of the HZD4060 Alarm Panel and supporting equipment.
        Visit <b-link href="https://www.boc-healthcare.com.au/en/hospital_services/" target="_blank">BOC Healthcare Australia</b-link> or
        <b-link href="https://www.boc-healthcare.co.nz/en/products_and_services/hospital_services/" target="_blank">BOC Healthcare New Zealand</b-link> for more information.
    </MainSection>

    <hr/>

    <MainSection
      title="Industry Standards"
      textSide="left"
      imagePath=""
      >
        <b-container>
        <b-row>
        <b-col lg="8" cols="10">
        <br/>
        All-Systems Electronics take great pride in understanding the Quality expectations of our customers and the industry on the whole.<br/>
        <br/>
        Our Quality Management System is certified to ISO 9001 by SAI Global. The Quality Management System is incorporated into every aspect of our business, including manufacturing, design and development,
        and our business practices.
        </b-col>
        <b-col lg="4" cols="10" align="center">
          
        <b-img :src="require('../assets/ISO9001.png')" width="85rem"></b-img>
        </b-col>
        </b-row>
        <br/>
        <b-row>
        We also design and manufacture several products to Australian and International Standards, including AS3547 for Breath Alcohol Testing Devices, and AS2896 for Medical Gas Systems.
        We work closely with SAI Global, TestSafe NSW and Austest to ensure our products are certified, tested, and meet all required industry standards.
        We are able to work with you to ensure your product meets and passes all appropriate certifications.
        </b-row>
        </b-container>
    </MainSection>
    
    <Blurb>
      Whatever the application, <b>All-Systems</b> can help you <b>conceptualise</b>, <b>design</b> and locally <b>manufacture</b> a product that meets your requirements as well as all relevant <b>industry standards</b>.
    </Blurb>
    
    <br />

    </b-container>

  </div>
</template>

<script>
import IndustrySummary from './IndustrySummary.vue';
import MainSection from './MainSection.vue';
import Blurb from './Blurb.vue';

export default {
  name: 'Home',
  components: {
    IndustrySummary,
    MainSection,
    Blurb
  },
}
</script>
<style>

@media (max-width: 768px) {
    .b-carousel-slide h3 {
        font-size: 1.3rem;
    }
    .b-carousel-slide p {
        font-size: 0.8rem;
    }
}

.b-carousel-slide {
    max-height: 54vh;
}
.carousel-caption
			{
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 10;
				padding-top: 20px;
				padding-bottom: 20px;
				color: #fff;
				text-align: center;
				background: rgba(0,0,0,0.3);
      }

.card-img-top {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    text-align: center;
}

</style>